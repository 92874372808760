import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";

const NavbarBackground = styled.nav`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
`;

const NavbarCollapseUl = styled.ul`
  margin: 0 auto;
  text-align: center;
  list-style: none;
  text-transform: uppercase;
  border-style: solid;
  border-width: 1px 0;
  border-color: #cccccc;
`;

const NavbarCollapseLi = styled.li<{ isActive: boolean }>`
  float: none;
  display: inline-block;
  color: #454545;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 0.3em;
  border-color: ${(props) => (props.isActive ? "#454545" : "transparent")};
  transition: border-color 300ms, background-color 500ms;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  padding: 0 20px;
  height: 4em;
  line-height: 4em;
  display: block;
  user-select: none;
`;

export default function TableOfContent() {
  let location = useLocation();

  return (
    <NavbarBackground>
      <NavbarCollapseUl>
        <NavbarCollapseLi isActive={location.pathname === "/"}>
          <StyledNavLink to="/">Home</StyledNavLink>
        </NavbarCollapseLi>
        <NavbarCollapseLi isActive={location.pathname === "/detailed-cv"}>
          <StyledNavLink to="/detailed-cv">Detailed CV</StyledNavLink>
        </NavbarCollapseLi>
      </NavbarCollapseUl>
    </NavbarBackground>
  );
}
