import styled from "@emotion/styled";
import { AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleTime } from "@visx/scale";
import { Bar, Polygon, LinePath } from "@visx/shape";
import { PatternLines } from "@visx/pattern";
import { differenceInSeconds, addMonths, differenceInYears } from "date-fns";
import JobItem from "./JobItem";
import logoHotjar from "../assets/vector/compress/hotjar.svg";
import logoPrezi from "../assets/vector/compress/prezi.svg";
import logoGE from "../assets/vector/compress/ge.svg";
import logoVSC from "../assets/vector/compress/vsc.svg";
import logoCNAM from "../assets/vector/compress/secu.svg";
import logoSFR from "../assets/vector/compress/SFR.svg";
import logoBouygues from "../assets/vector/compress/bouygues.svg";
import logoUnivNantes from "../assets/vector/compress/univ.svg";
import logoINSA from "../assets/vector/compress/insa.svg";
import logoSopra from "../assets/vector/compress/sopra.svg";
import BubbleItem from "./BubbleItem";

const SVG = styled.svg`
  max-width: 100%;
`;

// scales
const timeScale = scaleTime<number>({
  domain: [addMonths(new Date(), 6), addMonths(new Date("2007-09-01"), -6)],
});

const periodTimeScale = scaleTime<number>({
  domain: [
    0,
    differenceInSeconds(
      addMonths(new Date(), 6),
      addMonths(new Date("2007-09-01"), -6)
    ),
  ],
});

const eventList = [
  {
    endDate: new Date(),
    startDate: new Date("2020-12-01"),
    color: "#ff3400ff",
    title: "Frontend Developer (Javascript)",
    subTitle: "Hotjar, Remote",
    logoSrc: logoHotjar,
    navigateLink: "/detailed-cv#hotjar",
  },
  {
    endDate: addMonths(new Date("2018-02-01"), 1 * 12 + 11 + 11),
    startDate: new Date("2018-02-01"),
    color: "#318bffff",
    title: "Full stack engineer (Python/Javascript)",
    subTitle: "Prezi, Budapest",
    logoSrc: logoPrezi,
    navigateLink: "/detailed-cv#prezi",
  },
  {
    endDate: addMonths(new Date("2016-10-01"), 1 * 12 + 5),
    startDate: new Date("2016-10-01"),
    color: "#3b73b9ff",
    title: "Frontend Developer (Javascript)",
    subTitle: "GE Healthcare, Budapest",
    logoSrc: logoGE,
    navigateLink: "/detailed-cv#ge",
  },
  {
    endDate: addMonths(new Date("2014-04-01"), 2 * 12 + 6),
    startDate: new Date("2014-04-01"),
    color: "#67bd30ff",
    title: "Backend Devops (Java)",
    subTitle: "Voyages-SNCF.com, Nantes",
    logoSrc: logoVSC,
    navigateLink: "/detailed-cv#vsc",
  },
  {
    endDate: addMonths(new Date("2013-07-01"), 9),
    startDate: new Date("2013-07-01"),
    color: "#2059a6ff",
    title: "Full stack developer (Java)",
    subTitle: "CNAM, Nantes",
    logoSrc: logoCNAM,
    navigateLink: "/detailed-cv#cnam",
  },
  {
    endDate: addMonths(new Date("2012-12-01"), 7),
    startDate: new Date("2012-12-01"),
    color: "#ef0004ff",
    title: "Backend developer (Java)",
    subTitle: "SFR, Nantes",
    logoSrc: logoSFR,
    navigateLink: "/detailed-cv#sfr",
  },
  {
    endDate: addMonths(new Date("2012-04-01"), 8),
    startDate: new Date("2012-04-01"),
    color: "#009dccff",
    title: "Backend developer (C)",
    subTitle: "Bouygues Telecom, Nantes",
    logoSrc: logoBouygues,
    navigateLink: "/detailed-cv#bouygues",
  },
  {
    endDate: new Date("2012-04-01"),
    startDate: new Date("2011-09-01"),
    color: "#173565ff",
    title: "Master’s degree: Additional skills in Informatics",
    subTitle: "Faculty of Science, Nantes",
    logoSrc: logoUnivNantes,
    navigateLink: "/detailed-cv#univ",
  },
  {
    endDate: new Date("2010-07-01"),
    startDate: new Date("2007-09-01"),
    color: "#ee3524ff",
    title: "Master’s degree: Civil and Urban engineering",
    subTitle: "INSA, Rennes and BME, Budapest",
    logoSrc: logoINSA,
    navigateLink: "/detailed-cv#insa",
  },
];

export default function History() {
  const width = 680;
  const height =
    (1200 * differenceInSeconds(new Date(), new Date("2007-09-01"))) /
    differenceInSeconds(new Date("2021-01-22"), new Date("2007-09-01"));
  const margin = {
    left: 40,
    right: 40,
    top: 20,
    bottom: 20,
  };
  // bounds
  const yMax = height - margin.top - margin.bottom;
  const barWidth = 20;

  timeScale.range([0, yMax]);
  periodTimeScale.range([0, yMax]);

  return (
    <SVG
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin meet"
    >
      <PatternLines
        id="lines"
        height={5}
        width={5}
        stroke={"#173565ff"}
        strokeWidth={2}
        orientation={["diagonal"]}
      />
      <Group left={margin.left} top={margin.top}>
        <AxisLeft
          scale={timeScale}
          hideAxisLine
          numTicks={differenceInYears(new Date(), new Date("2007-09-01"))}
        />
        <Polygon
          sides={3}
          rotate={90}
          size={20}
          center={{ x: 10, y: 15 }}
          fill={"#ecececff"}
        />
        <Bar
          width={barWidth}
          height={periodTimeScale(
            differenceInSeconds(
              addMonths(new Date(), 3),
              addMonths(new Date("2007-09-01"), -6)
            )
          )}
          y={timeScale(addMonths(new Date(), 3))}
          fill={"#ecececff"}
        />
        {eventList.map((item) => (
          <JobItem
            key={item.subTitle}
            endDate={item.endDate}
            startDate={item.startDate}
            color={item.color}
            timeScale={timeScale}
            periodTimeScale={periodTimeScale}
            title={item.title}
            subTitle={item.subTitle}
            logoSrc={item.logoSrc}
            navigateLink={item.navigateLink}
          />
        ))}
        <Bar
          width={barWidth}
          height={periodTimeScale(
            differenceInSeconds(new Date("2012-07-01"), new Date("2012-04-01"))
          )}
          fill="url('#lines')"
          x={0}
          y={timeScale(new Date("2012-07-01"))}
        />
        <LinePath
          data={[
            {
              x: 400,
              y: timeScale(new Date("2013-08-01")),
            },
            {
              x: 320,
              y: timeScale(new Date("2013-08-01")),
            },
          ]}
          x={(d) => d.x}
          y={(d) => d.y}
          strokeWidth={5}
          stroke="#ecececff"
        />
        <LinePath
          data={[
            {
              x: 400,
              y: timeScale(new Date("2013-08-01")),
            },
            {
              x: 320,
              y: timeScale(addMonths(new Date("2013-08-01"), -8)),
            },
          ]}
          x={(d) => d.x}
          y={(d) => d.y}
          strokeWidth={5}
          stroke="#ecececff"
        />
        <LinePath
          data={[
            {
              x: 400,
              y: timeScale(new Date("2013-08-01")),
            },
            {
              x: 320,
              y: timeScale(addMonths(new Date("2013-08-01"), 8)),
            },
          ]}
          x={(d) => d.x}
          y={(d) => d.y}
          strokeWidth={5}
          stroke="#ecececff"
        />
        <BubbleItem
          x={400}
          y={timeScale(new Date("2013-08-01"))}
          title={"Mission as subcontractor"}
          subTitle={"SOPRA STERIA, Nantes"}
          logoSrc={logoSopra}
          navigateLink="/detailed-cv#cnam"
        />
      </Group>
    </SVG>
  );
}
