import styled from "@emotion/styled";
import guy from "./assets/images/guy.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import backgroundImage from "./assets/images/bg_head.jpg";

const DivHead = styled.div`
  background: #f4f4f4 url(${backgroundImage}) top center;
  background-size: auto;
  background-size: cover;
  color: #7c7c7c;
  padding: 30px 0 35px 0;
`;

const DivLogo = styled.div`
  text-align: center;
  font-family: "Open sans", Helvetica, Arial, sans-serif;
`;

const ImgCircle = styled.img`
  display: block;
  width: 140px;
  height: 140px;
  overflow: hidden;
  border: 9px solid rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  border-radius: 50%;
  max-width: 100%;
  box-sizing: border-box;
`;

const LogoTitle = styled.p`
  font-size: 3rem;
  font-family: Alice, Georgia, serif;
  font-weight: 400;
  color: #333333;
  line-height: 1.1;
  margin: 0;
`;

const LogoTagLine = styled.p`
  font-size: 1.2rem;
  font-family: "Open sans", Helvetica, Arial, sans-serif;
  margin: 15px 0 0;
  line-height: 1.2em;
  color: #7c7c7c;
  font-weight: 500;
`;

const LogoExternalLink = styled.p`
  line-height: 1.2em;
  margin: 15px 0 0;
  color: #333333;
  outline: 0;
  text-decoration: none;
  font-size: 1.7rem;
`;

const ExternalLink = styled.a`
  color: inherit;
  text-decoration: none;
  border-bottom: solid;
  border-bottom-width: 0;
`;

export default function Header() {
  return (
    <header>
      <DivHead>
        <DivLogo>
          <ImgCircle src={guy} alt="guy" />
          <LogoTitle>Samuel Gagnepain</LogoTitle>
          <LogoTagLine>Just my online CV</LogoTagLine>
          <LogoExternalLink>
            <ExternalLink href="https://github.com/Winbee">
              <FontAwesomeIcon icon={faGithubSquare} />
            </ExternalLink>
            &nbsp;
            <ExternalLink href="https://www.linkedin.com/in/samuel-gagnepain-042b7290">
              <FontAwesomeIcon icon={faLinkedin} />
            </ExternalLink>
          </LogoExternalLink>
        </DivLogo>
      </DivHead>
    </header>
  );
}
