import styled from "@emotion/styled";
import Header from "../../Header";
import Main from "../../Main";

import { BrowserRouter } from "react-router-dom";
import TableOfContent from "../../TableOfContent";

const AppContainer = styled.div`
  position: relative;
`;

export default function App() {
  return (
    <AppContainer className="App">
      <BrowserRouter>
        <Header></Header>
        <TableOfContent />
        <Main></Main>
        <footer></footer>
      </BrowserRouter>
    </AppContainer>
  );
}
