import { Group } from "@visx/group";
import { Bar, Line } from "@visx/shape";
import { ScaleTime } from "d3-scale";
import { differenceInSeconds } from "date-fns";
import BubbleItem from "./BubbleItem";

const barWidth = 20;
const lineWidth = 3;

interface JobItemProps {
  startDate: Date;
  endDate: Date;
  color: string;
  timeScale: ScaleTime<number, number>;
  periodTimeScale: ScaleTime<number, number>;
  title: string;
  subTitle: string;
  logoSrc: string;
  navigateLink: string;
}

export default function JobItem({
  startDate,
  endDate,
  color,
  timeScale,
  periodTimeScale,
  title,
  subTitle,
  logoSrc,
  navigateLink,
}: JobItemProps) {
  return (
    <Group left={0} top={timeScale(endDate)}>
      <Line
        from={{ x: 0, y: lineWidth / 2 }}
        to={{ x: 70, y: lineWidth / 2 }}
        stroke={color}
        strokeWidth={lineWidth}
      />
      <BubbleItem
        x={70}
        y={lineWidth / 2}
        title={title}
        subTitle={subTitle}
        logoSrc={logoSrc}
        navigateLink={navigateLink}
      />
      <Bar
        width={barWidth}
        height={periodTimeScale(differenceInSeconds(endDate, startDate))}
        fill={color}
      />
    </Group>
  );
}
