import styled from "@emotion/styled/macro";
import { Group } from "@visx/group";
import { Circle } from "@visx/shape";
import { Text } from "@visx/text";

const circleRadius = 22;

interface JobItemProps {
  x: number;
  y: number;
  title: string;
  subTitle: string;
  logoSrc: string;
  navigateLink?: string;
}

const StyledCircle = styled(Circle)`
  transition: all 0.2s ease-in-out;
`;

const StyledGroup = styled(Group)`
  cursor: pointer;
  &:hover {
    ${StyledCircle} {
      fill: #9e9e9e;
    }
  }
`;

export default function BubbleItem({
  title,
  subTitle,
  logoSrc,
  x,
  y,
  navigateLink,
}: JobItemProps) {
  return (
    <StyledGroup
      left={x}
      top={y}
      onClick={() => {
        // For an unknow reason, useNavigate doesn't work with hash link
        navigateLink && window.location.assign(navigateLink);
      }}
    >
      <Group>
        <StyledCircle fill={"#ecececff"} r={circleRadius} />
        <image
          x={(-circleRadius * 1.2) / 2}
          y={(-circleRadius * 1.2) / 2}
          href={logoSrc}
          height={circleRadius * 1.2}
          width={circleRadius * 1.2}
        />
      </Group>
      <Text x={circleRadius * 1.5} y={-6}>
        {title}
      </Text>
      <Text x={circleRadius * 1.5} y={15} fill="#979797">
        {subTitle}
      </Text>
    </StyledGroup>
  );
}
