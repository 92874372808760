import styled from "@emotion/styled";
import Home from "./Home";
import Detail from "./Detail";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
`;

export default function Main() {
  let location = useLocation();

  return (
    <main>
      <Container>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="fade" timeout={400}>
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/detailed-cv" element={<Detail />} />
              <Route path="*" element={() => <Navigate replace to="/" />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </main>
  );
}
