import styled from "@emotion/styled";
import logoHotjar from "./assets/vector/compress/hotjar.svg";
import logoPrezi from "./assets/vector/compress/prezi.svg";
import logoGE from "./assets/vector/compress/ge.svg";
import logoVSC from "./assets/vector/compress/vsc.svg";
import logoCNAM from "./assets/vector/compress/secu.svg";
import logoSFR from "./assets/vector/compress/SFR.svg";
import logoBouygues from "./assets/vector/compress/bouygues.svg";
import logoUnivNantes from "./assets/vector/compress/univ.svg";
import logoINSA from "./assets/vector/compress/insa.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

const ImgLogo = styled.img`
  height: 3em;
`;

const GraySpan = styled.span`
  color: #bebebe;
`;

const StickyTitle = styled.div`
  position: sticky;
  top: 4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const StyledH2 = styled.h2`
  padding: 10px 0px 5px;
`;

export default function Detail() {
  return (
    <div>
      <h1>
        <FontAwesomeIcon icon={faUserTie} /> - Work experience
      </h1>

      <hr id="hotjar" />
      <div>
        <StickyTitle>
          <StyledH2>
            Frontend developer <GraySpan>@Hotjar</GraySpan>
          </StyledH2>
          <ImgLogo src={logoHotjar} alt="Hotjar" />
        </StickyTitle>
        <h3>Description</h3>
        <p>
          Hotjar is a behavior analytics company that analyses website use,
          providing feedback through tools such as heatmaps, session recordings,
          and surveys.
        </p>
        <p>Our team is responsible for:</p>
        <ul>
          <li>Developing the dashboard and trends pages</li>
          <li>Developing the global filters</li>
          <li>Developing the user attributes service</li>
        </ul>
        <p>
          I was previously working on the recording list page and recording
          player.
        </p>
        <h3>Date</h3>
        <p>December 2020 - still working there today</p>
        <h3>Contract</h3>
        <p>Permanent contract at Hotjar, Remote</p>
        <h3>Duties</h3>
        <ul>
          <li>
            Developing/Testing/Deploying new features on react frontend
            application
          </li>
          <li>Migratin old features from Angular to React</li>
          <li>
            Create and describe solutions to implement and manage the technical
            side of a project
          </li>
          <li>
            Reacting to outages in production if it involves our team ownership
          </li>
          <li>Following scrum principles</li>
          <li>English speaking environment</li>
          <li>Full remote environment</li>
        </ul>
        <h3>Technology used</h3>
        <p>
          Javascript/TypeScript, React, AngularJs, Git, Gitlab, Jenkins, NPM,
          Webpack, Jira, Confluence, Miro, Datadog, Docker,
        </p>
        <hr id="prezi" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>
            Full Stack Engineer <GraySpan>@Prezi</GraySpan>
          </StyledH2>
          <ImgLogo src={logoPrezi} alt="Prezi" />
        </StickyTitle>
        <h3>Description</h3>
        <p>
          Prezi is a presentation software company. Internally, each team owns
          its services from development to production.
        </p>
        <p>Our team is responsible for:</p>
        <ul>
          <li>
            Developing and serving a viewer to the user on the web (front/back)
          </li>
          <li>
            Developing and serving Prezi templates to other services of the
            infrastructure (back only)
          </li>
          <li>
            Developing and serving a service to generate previews of a Prezi
            (back only)
          </li>
          <li>Serving the Prezi editor (back only)</li>
          <li>Developing parts of the Prezi editor (front)</li>
        </ul>
        <h3>Date</h3>
        <p>February 2018 - 2 years 9 months</p>
        <h3>Contract</h3>
        <p>Permanent contract at Prezi, Budapest</p>
        <h3>Duties</h3>
        <ul>
          <li>
            Developing new features on backend (Django) and frontend (React)
            applications
          </li>
          <li>
            Create and describe solutions to implement and manage the technical
            side of a project
          </li>
          <li>Installing and testing our app on AWS</li>
          <li>
            Deploying, maintaining and reacting to outages in production
            (rotating shift inside our team)
          </li>
          <li>Following scrum principles</li>
          <li>English speaking environment</li>
        </ul>
        <h3>Technology used</h3>
        <p>
          Javascript/TypeScript, React, Python, Django, Cucumber (Ruby), Git,
          Github, Jenkins, NPM, Webpack, Jira, Confluence, Miro, Kibana,
          Grafana, Docker, Pager duty
        </p>
        <p>on the AWS stack: EC2, DynamoDB, RDS, S3, Route53</p>

        <hr id="ge" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>
            Frontend Developer <GraySpan>@GE</GraySpan>
          </StyledH2>
          <ImgLogo src={logoGE} alt="General Electric" />
        </StickyTitle>
        <h3>Description</h3>
        <p>2 projects:</p>
        <ul>
          <li>
            Web viewer used to display and analyse medical image for GE
            Healthcare
          </li>
          <li>
            Web application to manage medical consultation during olympic games
          </li>
        </ul>
        <h3>Date</h3>
        <p>October 2016 - 1 year 5 months</p>

        <h3>Contract</h3>
        <p>Permanent contract at GE Healthcare, Budapest</p>

        <h3>Duties</h3>
        <ul>
          <li>Developing new features in AngularJS and Angular 4</li>
          <li>Developing lambdas on AWS</li>
          <li>Installing and testing our app on AWS</li>
          <li>Following scrum principles</li>
          <li>Following medical regulation</li>
          <li>Developing solutions to analyse test result (Kibana)</li>
          <li>English speaking environment</li>
        </ul>

        <h3>Technology used</h3>
        <p>
          Javascript, Angular (V1 and V4), AWS, Cucumber (JS and Java), Git,
          Github, Jenkins, Sonar, Maven, NPM, Bower, Jira, Confluence, Kibana,
          Docker
        </p>

        <hr id="vsc" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>
            Backend Devops <GraySpan>@voyages.sncf.com</GraySpan>
          </StyledH2>
          <ImgLogo src={logoVSC} alt="Voyages.sncf.com" />
        </StickyTitle>
        <h3>Description</h3>
        <p>
          Backend software to distribute railway product for SNCF, Sabre,
          Amadeus and others. I was working on two interconnected softwares.
        </p>

        <h3>Date</h3>
        <p>April 2014 - 2 years 5 months</p>

        <h3>Contract</h3>
        <p>
          Permanent contract at SOPRA STERIA and then permanent contract at
          Voyages-sncf.com, Nantes
        </p>

        <h3>Duties</h3>
        <ul>
          <li>Following scrum principles</li>
          <li>Developing and testing new features in Java EE</li>
          <li>SOAP and REST webservices</li>
          <li>Batch</li>
          <li>Database connectors (SQL database and Mongo)</li>
          <li>Create and describe solutions to implement</li>
        </ul>

        <h3>Technology used</h3>
        <p>
          Java EE, Groovy, Cucumber, Tomcat, Maven, Git, SVN, Jenkins, Sonar,
          Linux environment, Mongo, Yaml, Kibana, Grafana, Jackson, Jira,
          Confluence
        </p>

        <hr id="cnam" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>
            Full stack developer <GraySpan>@CNAM</GraySpan>
          </StyledH2>
          <ImgLogo
            src={logoCNAM}
            alt="Caisse Nationale Assurance Maladie des Travailleurs Salaries"
          />
        </StickyTitle>
        <h3>Description</h3>
        <p>
          Web portal for healthcare professionals for CNAM (Public Services).
          Back and Front Office Multi-modules project build on Oracle Weblogic
          Portal. Its’ purpose is to assist professionals in their daily
          routine.
        </p>

        <h3>Date</h3>
        <p>July 2013 - 9 Months</p>

        <h3>Contract</h3>
        <p>Permanent contract at SOPRA STERIA, Nantes</p>

        <h3>Duties</h3>
        <ul>
          <li>
            Documentation/Implementation/Test of a batch processing statistical
            data (Spring Batch, Top Link)
          </li>
          <li>
            Meeting with clients to present and explain how the batch works
          </li>
          <li>
            Implementation/Test of the web interface which present the results
            of the previous batch (Struts)
          </li>
          <li>
            Modification of the web interface of several module on the portal
            (Struts, Spring MVC)
          </li>
          <li>Implementation/Test a new SOAP Webservice (Spring Core, Axis)</li>
        </ul>

        <h3>Technology used</h3>
        <p>
          Java EE, Oracle Weblogic Portal, Oracle Weblogic server, Ant, CVS,
          Struts, Spring Core, Spring MVC, Spring Batch, Top Link, JUnit, Dozer,
          Sonar, Jenkins, Windows environment
        </p>

        <hr id="sfr" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>
            Backend developer <GraySpan>@SFR</GraySpan>
          </StyledH2>
          <ImgLogo src={logoSFR} alt="SFR" />
        </StickyTitle>
        <h3>Description</h3>
        <p>
          Ordering management system for SFR (Telecommunication). Back office
          project that deals with requests/orders coming from several front
          office applications. Large team (70 persons) situated in three places
          (Nantes, Rennes and Paris).
        </p>

        <h3>Date</h3>
        <p>December 2012 - 7 Months</p>

        <h3>Contract</h3>
        <p>Permanent contract at SOPRA STERIA, Nantes</p>

        <h3>Duties</h3>
        <ul>
          <li>
            Modification of existing code to make several requests compatible
            (Java, SQL, HQL)
          </li>
          <li>Modification of a SOAP Webservice (Java, WSDL, Soap UI)</li>
          <li>Implementation of a new SOAP Webservice (Java, WSDL, Soap UI)</li>
          <li>Implementation of a new job for the Quartz scheduler (Java)</li>
          <li>
            Implementation of a new batch that re-launch a set of failed command
          </li>
          <li>Correcting and solving anomalies and defects</li>
        </ul>

        <h3>Technology used</h3>
        <p>
          Java EE, Tomcat, Spring Core, Spring Batch, Hibernate, Quartz, JUnit,
          Maven, SVN, Jenkins, Sonar, Gerrit, Windows environment.
        </p>

        <hr id="bouygues" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>
            Backend developer <GraySpan>@Bouygues Telecom</GraySpan>
          </StyledH2>
          <ImgLogo src={logoBouygues} alt="Bouygues Telecom" />
        </StickyTitle>
        <h3>Description</h3>
        <p>
          Collecting of communication tickets for Bouygues Telecom
          (Telecommunication). Third Party Maintenance of the back office
          application that gather, transform and dispatch tickets.
        </p>

        <h3>Date</h3>
        <p>April 2012 - 8 Months</p>

        <h3>Contract</h3>
        <p>Permanent contract at SOPRA STERIA, Nantes</p>

        <h3>Duties</h3>
        <ul>
          <li>Script migration from RedHat to Sun, HP-UX, AIX (Ksh)</li>
          <li>
            Implementation/modification of the processing of the tickets (C,
            Xml)
          </li>
          <li>
            Modification of tickets conversion script from ascii/asn1 (Ksh, C)
          </li>
          <li>Correcting and solving anomalies and defects (Core analysis)</li>
        </ul>

        <h3>Technology used</h3>
        <p>C, Ksh, Xml, Continuus, Unix Environment</p>

        <br />
        <br />
        <hr id="univ" />
      </div>
      <h1>
        <FontAwesomeIcon icon={faGraduationCap} /> - Academic qualification
      </h1>
      <hr />
      <div>
        <StickyTitle>
          <StyledH2>Master's Degree: Additional skills in informatics</StyledH2>
          <ImgLogo src={logoUnivNantes} alt="Université de Nantes" />
        </StickyTitle>
        <h3>Date</h3>
        <p>September 2011 - July 2012</p>

        <h3>Description</h3>
        <p>
          One year study at the faculty of Science of Nantes (France). It was
          designed to give additional skills in informatics to none IT engineer
          profiles. It mainly focused on web technologies and new developing
          environment platform.
        </p>

        <hr id="insa" />
      </div>
      <div>
        <StickyTitle>
          <StyledH2>Master's Degree: Civil and Urban engineering</StyledH2>{" "}
          <ImgLogo src={logoINSA} alt="INSA de Rennes" />
        </StickyTitle>
        <h3>Date</h3>
        <p>September 2007 - July 2010</p>

        <h3>Description</h3>
        <p>
          Five years studies at INSA of Rennes (France) and BME of Budapest
          (Hungary). It was designed to shape general engineers in the domains
          of building, public works and urban planning.
        </p>
      </div>
    </div>
  );
}
