import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faUserTie,
  faGamepad,
  faAsterisk,
  faUser,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatDistanceToNow, differenceInYears } from "date-fns";
import History from "./history/History";

export default function Home() {
  const totalDurationString = formatDistanceToNow(
    new Date("2012-04-01T00:00:00")
  );
  const totalAge = differenceInYears(
    new Date(),
    new Date("1986-05-27T00:00:00")
  );
  return (
    <div>
      <h1>Fullstack developer - {totalDurationString}' experience</h1>
      <h2>
        <FontAwesomeIcon icon={faStar} /> - Area of expertise
      </h2>

      <ul>
        <li>TypeScript-javascript (React/Vue/Angular)</li>
        <li>Python (Django)</li>
        <li>Java (Spring: Core, MVC, Batch, Data)</li>
        <li>AWS</li>
      </ul>

      <h2>
        <FontAwesomeIcon icon={faUserTie} /> - Current position
      </h2>
      <p>Frontend developer at Hotjar (Remote)</p>

      <h2>
        <FontAwesomeIcon icon={faGamepad} /> - Personnal projects
      </h2>
      <ul>
        <li>
          <a href="https://github.com/Winbee/joplin-turn-to-chart">
            Joplin-turn-to-chart
          </a>{" "}
          : Plugin to transform a markdown table into d3js chart
        </li>
        <li>
          <a href="https://github.com/Winbee/cashcash-desktop">
            Cashcash-desktop
          </a>{" "}
          : Desktop app to visualize and manage your finances
        </li>
        <li>
          <a href="https://github.com/Winbee/SynoCI">SynoCI</a> : Guide to
          install a continuous integration infrastructure on a NAS Synology
        </li>
      </ul>

      <h2>
        <FontAwesomeIcon icon={faAsterisk} /> - Others details
      </h2>
      <ul>
        <li>Bilingual French/English</li>
        <li>{totalAge} years old</li>
      </ul>

      <h2>
        <FontAwesomeIcon icon={faUser} /> - Personal summary
      </h2>
      <p>
        After working a year in civil engineering, I realized that I was more
        interested in informatics.
      </p>
      <p>
        Completing a master's degree of Additional skills in Informatics, I got
        an internship and then a permanent contract at a leading IT services
        company, Sopra Steria. I rediscovered the pleasure to innovate, produce
        concrete solutions and learn new things.
      </p>

      <p>
        In Sopra Steria I got a mission to work for Voyages-sncf.com, the first
        e-commerce website in France. After a year, the company chose to hire me
        with a permanent contract. This experience taught me to think in an
        agile way and made me explore lots of new technologies.
      </p>

      <p>
        I then moved to Budapest (Hungary) where I discovered the frontend side
        of development for GE Healthcare. Not long after, I seized an
        opportunity for a wider spectrum of responsabilities at Prezi as a Full
        stack engineer.
      </p>

      <p>I switched to a remote position in Hotjar as a Frontend developer.</p>

      <h2>
        <FontAwesomeIcon icon={faCalendarAlt} /> - History graph
      </h2>

      <History />
    </div>
  );
}
